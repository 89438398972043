import * as React from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import Layout from "../components/layout";
import OtherItems from "../components/otherItems";

// markup
const NotFoundPage = () => {
  return (
    <Layout title="Page Not Found">
      <Container>
        <Grid maxWidth="lg">
          <Grid item xs={12}>
            <Typography component="h1" variant="h3" sx={{ mt: 2 }}>
              Uh oh, you're on mute
            </Typography>
            <Typography variant="body1">
              The page you are looking for cannot be found. May we suggest
              browsing in&nbsp;
              <Link href="/messages">Messages</Link>.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <OtherItems cols={4} />
      {process.env.NODE_ENV === "development" ? (
        <>
          <Typography component="h2" variant="h5">
            DEV MODE
          </Typography>
          <br />
          Try creating a page in <code>src/pages/</code>.
          <br />
        </>
      ) : null}
    </Layout>
  );
};

export default NotFoundPage;
